import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
    }

    preLoad() {

    }

    setup() {

        //We take advantage of the Basic3DContext class to get a predefined scene
        Mobilizing.Basic3DContext.makeBasicContext(this.context);

        this.context.camera.transform.setLocalPositionZ(20);

        const button = new Mobilizing.three.Button({
            camera: this.context.camera,
            pointer: this.context.pointer,
            width: 4,
            height: 1,
            strokeWidth: .1,
            text: "<b>CLICK ME...</b>",

            onPress: function () {
                console.log("you clicked me!", this);
            }
        });

        this.context.addComponent(button);
        button.setup();

        this.context.renderer.addToCurrentScene(button.root);
    }

    update() {

    }
}
