import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {

        this.bufferRequest1 = loader.loadArrayBuffer({ url: "./assets/audio/harp1.wav" });
        this.bufferRequest2 = loader.loadArrayBuffer({ url: "./assets/audio/harp2.wav" });
    }

    setup() {

        this.audioRenderer = new Mobilizing.audio.Renderer();
        this.context.addComponent(this.audioRenderer);

        this.threeRenderer = new Mobilizing.three.RendererThree({ preserveDrawingBuffer: true });
        this.context.addComponent(this.threeRenderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.setFOV(80);
        this.threeRenderer.addCamera(this.camera);

        const light = new Mobilizing.three.Light();
        light.setDistance(10000);
        this.threeRenderer.addToCurrentScene(light);

        const posBase = 600;

        const circle = new Mobilizing.three.Sphere({ "radius": 20 });
        circle.transform.setLocalPosition(posBase, 0, 0);
        this.threeRenderer.addToCurrentScene(circle);

        const circle2 = new Mobilizing.three.Sphere({ "radius": 20 });
        circle2.transform.setLocalPosition(-posBase, 0, 0);
        this.threeRenderer.addToCurrentScene(circle2);

        //audio
        this.audioRenderer.setListenerTransform(this.camera.transform);

        const source = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true,
            "loop": true
        });
        this.context.addComponent(source);
        source.setRefDistance(100);

        const sound = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest1.getValue(),
            "decodedCallback": () => {
                source.setBuffer(sound);
                //play sounds
                if (!source.playing) {
                    source.play();
                }
            }
        });

        const source2 = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true
        });
        this.context.addComponent(source2);

        source2.setLoop(true);
        source2.setRefDistance(100);

        const sound2 = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest2.getValue(),
            "decodedCallback": () => {
                source2.setBuffer(sound2);
                if (!source2.playing) {
                    source2.play();
                }
            }
        });

        source.setTransform(circle.transform);
        source2.setTransform(circle2.transform);

        const cube = new Mobilizing.three.Cube({
            "material": "basic",
            "segments": 8
        });
        cube.transform.setLocalPosition(0, 0, 0);
        cube.transform.setLocalScale(10, 10, 10);
        cube.material.setWireframe(true);
        this.threeRenderer.addToCurrentScene(cube);

        //inputs
        this.orientation = new Mobilizing.input.Orientation();
        this.context.addComponent(this.orientation);
        this.orientation.setup();
        this.orientation.on();

        this.gyroQuat = new Mobilizing.three.Quaternion();
    }

    update() {

        this.gyroQuat.setFromGyro(this.orientation.compass);

        if (this.gyroQuat) {
            this.camera.transform.setLocalQuaternion(this.gyroQuat);
        }
    }

}
