import * as Mobilizing from '@mobilizing/library';
/**
 * Demonstrate how to use custom shaders programs in Mobilizing.js rendering (using Three.js)
 * This is an hommage to the 120 Variations on the Platonic Solids: "Perspectiva Corporum Regularium" by Wenzel Jamnitzer (1568).
 * According to the Metropolitan Museum of Art it can be describes like this :
 * Perspective of the Regular Bodies is a lavish compendium of perspectival geometry expressly intended to show off the graphic skills of Jamnitzer, perhaps the most renowned sixteenth-century goldsmith. Based on the five Platonic solids, or "regular bodies"—the tetrahedron, cube, octahedron, dodecahedron, and icosahedron—the five main sections of the book each consist of twenty-four polyhedral variants. The forms progress from the simplest on the top row of each page to the most augmented and complex at the bottom.
 * 
 * http://socks-studio.com/2016/11/04/120-variations-on-the-platonic-solids-perspectiva-corporum-regularium-by-wenzel-jamnitzer/
 * https://www.metmuseum.org/art/collection/search/339008
 */
export class Script {

    constructor() {
        //space between every shape
        this.backRadius = 1.5
        this.separation = this.backRadius * 2;
        this.shapeRotation = 0;
        this.lightRotation = 0;

        //lights position : front is for the polyhedrons demonstrated, back is for the half-sphere container
        this.frontLightPosition = new Mobilizing.three.Vector3(-10, 10, 10);
        this.backLightPosition = new Mobilizing.three.Vector3(this.separation / 2, -this.separation, -20);

        //all groups of shapes
        this.groups = [];
    }

    preLoad(loader) {
        //load the glsl files
        this.vertexShaderFile = loader.loadText({ "url": "./assets/shader/hatch.vert" });
        this.fragmentShaderFile = loader.loadText({ "url": "./assets/shader/hatch.frag" });
    }

    setup() {
        this.renderer = new Mobilizing.three.RendererThree({
            "antialias": true,
        });
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPosition(this.separation / 2, -this.separation, 15);
        this.renderer.addCamera(this.camera);

        this.renderer.setClearColor(Mobilizing.three.Color.lightGray.clone());

        const vertexShader = this.vertexShaderFile.getValue();
        const fragmentShader = this.fragmentShaderFile.getValue();

        //light for the polyhedrons : we use scene graph magic to rotate the light in update
        //that is : a node to which a light is attached
        this.lightGroup = new Mobilizing.three.Node();
        this.renderer.addToCurrentScene(this.lightGroup);

        this.light = new Mobilizing.three.Light();
        this.light.transform.setLocalPosition(this.frontLightPosition);
        this.light.setIntensity(1.1);
        this.renderer.addToCurrentScene(this.light);

        this.lightGroup.transform.addChild(this.light.transform);

        //light for the half-sphere container
        this.backLight = new Mobilizing.three.Light();
        this.backLight.transform.setLocalPosition(this.backLightPosition);
        this.renderer.addToCurrentScene(this.backLight);
        console.log(this.backLight);
        
        //shader uniforms values
        const uniforms = {
            "hatchDirection": { "value": new Mobilizing.three.Vector3(1, 1, 0) },
            "frequency": { "value": 80 },
            "edgeWidth": { "value": 0.8 },
            "lightness": { "value": this.light.getIntensity() / 10 },
            "scaleVector": { "value": new Mobilizing.three.Vector3(1, 1, 1) },
            "lightPosition": { "value": this.light.transform.getLocalPosition() },
            "color": { "value": Mobilizing.three.Color.white }
        }

        //custom shader material for polyhedras
        this.hatchMaterialFront = new Mobilizing.three.Material({
            "type": "custom",
            "customParams": {
                vertexShader,
                fragmentShader,
                uniforms
            }
        });

        //deep clone the uniforms to separete renderings between back sphere and polyhedra demo
        const uniformsBack = Mobilizing.util.cloneObject(uniforms);
        //custom shader material for backgroud sphere
        this.hatchMaterialBack = new Mobilizing.three.Material({
            "type": "custom",
            "customParams": {
                vertexShader,
                fragmentShader,
                "uniforms": uniformsBack
            }
        });
        //adjust back shader values
        this.hatchMaterialBack.setProperty("color", Mobilizing.three.Color.lightGray)
        this.hatchMaterialBack.setProperty("hatchDirection", new Mobilizing.three.Vector3(-1, 1, 0));
        this.hatchMaterialBack.setProperty("lightPosition", this.backLight.transform.getLocalPosition());
        this.hatchMaterialBack.setProperty("lightness", this.backLight.getIntensity() / 10);

        //groups
        const tetra = new Mobilizing.three.Tetrahedron({ "material": this.hatchMaterialFront });
        const tetraG = this.buildGroupForMesh(tetra);

        const cube = new Mobilizing.three.Cube({ "material": this.hatchMaterialFront });
        const cubeG = this.buildGroupForMesh(cube);

        const ico = new Mobilizing.three.Icosahedron({ "material": this.hatchMaterialFront });
        const icoG = this.buildGroupForMesh(ico);

        const octa = new Mobilizing.three.Octahedron({ "material": this.hatchMaterialFront });
        const octaG = this.buildGroupForMesh(octa);

        const dodeca = new Mobilizing.three.Dodecahedron({ "material": this.hatchMaterialFront });
        const dodecaG = this.buildGroupForMesh(dodeca);

        //composite mesh group
        const mixTetra = new Mobilizing.three.Tetrahedron({ "material": this.hatchMaterialFront });
        const mixCube = new Mobilizing.three.Cube({ "material": this.hatchMaterialFront });
        const mixIco = new Mobilizing.three.Icosahedron({ "material": this.hatchMaterialFront });

        mixIco.transform.setLocalScale(0.8);

        mixTetra.transform.addChild(mixCube.transform);
        mixTetra.transform.addChild(mixIco.transform);
        const mixG = this.buildGroupForMesh(mixTetra);

        tetraG.transform.setLocalPosition(0, 0, 0);
        cubeG.transform.setLocalPosition(this.separation, 0, 0);

        octaG.transform.setLocalPosition(0, -this.separation, 0);
        icoG.transform.setLocalPosition(this.separation, -this.separation, 0);

        dodecaG.transform.setLocalPosition(0, -this.separation * 2, 0);

        mixG.transform.setLocalPosition(this.separation, -this.separation * 2, 0);
    }

    //generic group bluilding function
    buildGroupForMesh(mesh) {
        //main node
        const group = new Mobilizing.three.Node();
        this.renderer.addToCurrentScene(group);
        //half-sphere
        const container = new Mobilizing.three.Sphere({
            "segments": 20,
            "radius": this.backRadius,
            "phiStart": Math.PI,
            "phiLength": Math.PI,
            "material": this.hatchMaterialBack //"line"
        });
        group.transform.addChild(container.transform);
        //add the polyhedron to the group
        group.transform.addChild(mesh.transform);
        this.groups.push(group);

        return group;
    }

    update() {
        //increments the shapes rotation
        this.shapeRotation += 0.1;

        for (let i = 0; i < this.groups.length; i++) {
            const group = this.groups[i];
            const shape = group.transform.getChild(1);
            shape.transform.setLocalRotation(-this.shapeRotation, -this.shapeRotation * 2, -this.shapeRotation * 3);
        }
        //increments the shapes light
        this.lightRotation += 0.1;
        this.lightGroup.transform.setLocalRotationZ(this.lightRotation);

        //update the shader attach to shapes material
        this.hatchMaterialFront.setProperty("lightPosition", this.light.transform.getWorldPosition());
    }
}
