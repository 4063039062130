
import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPosition(0, 0, 100);
        this.renderer.addCamera(this.camera);

        this.light = new Mobilizing.three.Light();
        this.light.transform.setLocalPosition(50, 0, 100);
        this.renderer.addToCurrentScene(this.light);

        const p = [];
        for (let i = 0; i < 64; i++) {
            p.push(new Mobilizing.three.Vector3(i, i % 2, 0));
        }

        this.mesh = new Mobilizing.three.Segments({
            "points": p,
        });
        this.renderer.addToCurrentScene(this.mesh);
        console.log("mesh", this.mesh.getVertices());
    }

    update() {
        const r = new Mobilizing.three.Vector3(0, Mobilizing.math.randomFromTo(-5,5), 0 );
        this.mesh.setPoint(0,r);
        //console.log(r);
    }
}
