import * as Mobilizing from '@mobilizing/library';

export class Script {

    preLoad() {

    }

    setup() {

        //We take advantage of the Basic3DContext class to get a predefined scene
        Mobilizing.Basic3DContext.makeBasicContext(this.context);

        //
        this.context.camera.setToPixel();

        const keys = "1234567890azertyuiopqsdfghjklmwxcvbn,;:=$%"
        const keyboardButtons = [];

        for (let i = 0; i < keys.length; i++) {

            const button = new Mobilizing.three.Button({
                camera: this.context.camera,
                pointer: this.context.pointer,
                radius: 1,
                strokeWidth: 0.1,
                text: keys.charAt(i),
                textSize: 50,

                onPress: function () {
                    console.log("you clicked me!", this);
                }
            });

            button.onPress = function () {
                console.log("clicked on", this.text);
            }

            this.context.addComponent(button);
            button.setup();

            keyboardButtons.push(button);
        }

        //organize them as a grid
        const grid1 = new Mobilizing.three.ButtonGroup({
            "buttons": keyboardButtons,
            "columns": 10,
            "mode": "honeycomb"
        });
        this.context.renderer.addToCurrentScene(grid1.root);

        grid1.transform.setLocalScale(30);
        grid1.computeSize();
        console.log(grid1.width, grid1.height);

        grid1.transform.setLocalPosition(
            this.context.renderer.getCanvasHalfWidth() - grid1.width / 2,
            -this.context.renderer.getCanvasHalfHeight() + grid1.height / 2,
            0);
    }

    update() {

    }
}
