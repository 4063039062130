import * as Mobilizing from '@mobilizing/library';
//a convinient addition to take screenshots from running examples
import { takeScreenShot } from './js/ScreenShot.js';
import { Script as MenuScript } from './js/Menu';
//file with all the examples scripts list and index. This is generate by a node script!
import { index, list } from './examples-list.js';
//highlight.js configuration
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);

import 'highlight.js/styles/github.css';

/**
 * We use this script as the main entry point for webpack. This is the entry index script.
 * So we check if we must show the global interface to access all the examples,
 * or if we must show one specific example that has been clicked by the user.
 */
function getScriptToRun() {
    let script = null;
    const scriptFromParamaters = Mobilizing.getUrlParameter("script");

    if (scriptFromParamaters) {
        script = index[scriptFromParamaters].Script;
        window.document.title = `Mobilizing.js Examples : ${scriptFromParamaters}`;

        Object.values(list).some((category) => {
            const example_id = Object.keys(category).find((k) => {
                return k === scriptFromParamaters;
            });

            if (example_id) {
                const example = category[example_id];

                Mobilizing.Loader.loadText(example.url, (response) => {
                    const source_viewer = document.createElement("pre");
                    source_viewer.classList.add("source-viewer");
                    source_viewer.innerHTML = response;
                    hljs.highlightBlock(source_viewer);
                    document.body.appendChild(source_viewer);
                });

                return true;
            }

            return false;
        });
    }
    else {
        script = MenuScript;
        window.document.title = "Mobilizing.js Examples Main Index";
    }

    return script;
}

//Runner, async to wait for user's click when sensors or audio needs it
async function run(script) {
    //usual Mobilizing begining
    const context = new Mobilizing.Context();
    const instance = new script();
    //check the script for the need of user interaction
    if (instance.needsUserInteraction) {
        context.userInteractionDone.setup();
        await context.userInteractionDone.promise;
    }
    context.addComponent(instance);

    const runner = new Mobilizing.Runner({ context });

    //after some time, check if we have a 3D rendrer to add
    //a screenshot function
    //the access to the script via a button for easy copy paste
    setTimeout(() => {
        //screenshot function addition
        const components = context.components;

        for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (component instanceof Mobilizing.three.RendererThree) {
                const renderer = component;
                //console.log("renderer =", renderer);
                takeScreenShot(renderer);
            }
        }
        const source_viewer = document.querySelector('pre.source-viewer');
        // show source bt
        if (source_viewer) {
            const srcOpenBt = document.createElement("button");
            srcOpenBt.classList.add('source-bt');
            srcOpenBt.classList.add('open');
            srcOpenBt.addEventListener("click", () => {
                document.body.classList.add('source-open');
            });
            document.body.appendChild(srcOpenBt);

            const srcCloseBt = document.createElement("button");
            srcCloseBt.classList.add('source-bt');
            srcCloseBt.classList.add('close');
            srcCloseBt.addEventListener("click", () => {
                document.body.classList.remove('source-open');
            });
            document.body.appendChild(srcCloseBt);
        }

    }, 1000);
}

const script = getScriptToRun();

if (script) {
    console.log("script", script.name);
    run(script);
}
