import * as Mobilizing from '@mobilizing/library';
import { list } from '../examples-list.js';

export class Script {

    constructor() {
        this.hasScreenshotParam = Mobilizing.getUrlParameter("screenshot");
        //console.log("list is ", examplesList);

        this.stacks = [];
        this.currentStackIndex = 0;

        this.stackZPos = -280;
        this.panelWidth = 60;
        this.panelHeight = 80;

        this.lockMouseWheelX = false;
        this.lockMouseWheelY = false;

        this.stackXSwipeLimit = 40;
        this.stackYSwipeLimit = 15;

        this.deviceType = Mobilizing.Device.getType();
        this.screenRatio = window.innerWidth / window.innerHeight;
        //console.log(this.screenRatio);

        if (this.screenRatio < 0.9) {
            this.stackZPos = -280 - ((1 + (1 - this.screenRatio)) * 150);
        }
        console.log("this.stackZPos", this.stackZPos);

        this.stackOffsetWidth = this.panelWidth * 1.7;
    }

    // called just after the constructor
    preLoad(loader) {
        //console.log("preLoad", loader);

        //const categories = Object.keys(examplesList);
        this.imageRequests = {};

        Object.values(list).forEach((category) => {
            Object.entries(category).forEach(([example_id, example]) => {
                //console.log("example", example);
                //do we have a screenshot to load ?
                if (example.screenshot) {
                    this.imageRequests[example_id] = loader.loadImage({ "url": example.screenshot });
                }
            });
        });

        this.fontRequest = loader.loadArrayBuffer({ "url": "./assets/fonts/Raleway-Bold.ttf" });
        //console.log(this.fontRequest);
    }

    // called when preloaded elements are loaded
    setup() {
        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);

        //inputs
        this.touch = this.context.addComponent(new Mobilizing.input.Touch({ "target": this.renderer.canvas }));
        this.touch.setup();//set it up
        this.touch.on();//active it

        this.mouse = this.context.addComponent(new Mobilizing.input.Mouse({ "target": this.renderer.canvas }));
        this.mouse.setup();//set it up
        this.mouse.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(this.touch);
        this.pointer.add(this.mouse);

        this.keyboard = new Mobilizing.input.Keyboard();
        this.context.addComponent(this.keyboard);
        this.keyboard.setup();//set it up
        this.keyboard.on();//active it

        const light = new Mobilizing.three.Light();
        light.setDistance(10000);
        light.transform.setLocalPosition(0, 100, 100);
        this.renderer.addToCurrentScene(light);

        this.renderer.setFog("linear");
        this.renderer.setFogNear(Math.abs(this.stackZPos) - 200);
        this.renderer.setFogFar(Math.abs(this.stackZPos) + 200);
        //------
        // Panel with title
        //------
        let panels = [];
        let categoryNb = 0;
        this.currentStack = null;

        Object.entries(list).forEach(([category_id, category]) => {
            console.log("category:", category_id);
            panels = [];

            Object.entries(category).forEach(([example_id, example]) => {
                console.log("example:", example_id);

                let texture = null;

                if (example.screenshot) {
                    texture = new Mobilizing.three.Texture({
                        "image": this.imageRequests[example_id].getValue()
                    });
                }

                const panelTitle = new Mobilizing.three.Panel({
                    "title": example_id,
                    "camera": this.camera,
                    "pointer": this.pointer,
                    "width": this.panelWidth,
                    "height": this.panelHeight,
                    texture,
                })

                //panelTitle.transform.setLocalPositionZ(-100);

                this.context.addComponent(panelTitle);
                panelTitle.setup();

                const hasScreenshotParam = this.hasScreenshotParam;

                panelTitle.events.on("up", function () {

                    this.setStrokeColor(Mobilizing.three.Color.mobilizingLight);
                    let url = `?script=${example_id}`;
                    if (hasScreenshotParam) {
                        url += `&screenshot=true`;
                    }
                    console.log("!!!you clicked", url);
                    //open it in a new tab
                    window.open(url);
                }.bind(panelTitle));

                panels.push(panelTitle);
            });

            const stackRadius = 100;

            //stack for these panels
            const stack = new Mobilizing.three.PanelStack({
                //"mode": "line",
                panels,
                "wheelRadius": stackRadius,
                "wheelVerticalFactor": 0.5,
                "wheelDepthFactor": 1,
                "lineHorizontalOffset": 10,
                "lineDepthOffset": 20,
                "pointer": this.pointer,
                "camera": this.camera,
            });

            //add it to the global stacks list for horizontal navigation
            this.stacks.push(stack);

            this.renderer.addToCurrentScene(stack);
            this.context.addComponent(stack);
            stack.setup();
            stack.on();

            const stackXPos = categoryNb * this.stackOffsetWidth;
            stack.transform.setLocalPosition(stackXPos, 0, this.stackZPos);
            //stack.transform.setLocalRotationY(20);

            //stack title
            const stackTitle = new Mobilizing.three.TextOpentype({
                "fontFile": this.fontRequest.getValue(),
                "fontSize": 10,
                "text": category_id.toUpperCase(),
                "depth": 0,
                //"bevelEnabled": true,
                //"bevelSize" : 0.3,
                "material": "basic"
            });
            stackTitle.material.setColor(Mobilizing.three.Color.mobilizing);

            this.renderer.addToCurrentScene(stackTitle);
            const stackTitleWidth = stackTitle.getSize().x;

            stackTitle.transform.setLocalRotationZ(90);
            stackTitle.transform.setLocalPosition(stackXPos - this.panelWidth / 1.5, -stackTitleWidth / 2, this.stackZPos + stackRadius);

            if (categoryNb === 0) {
                this.currentStack = stack;
                //console.log("this.currentStack", this.currentStack);
            }

            categoryNb++;
        });

        //touch interaction with current stack
        this.touch.events.on("swipeup", () => {
            console.log("swipeup");
            this.currentStack.stepTo(-1);
        });

        this.touch.events.on("swipedown", () => {
            console.log("swipedown");
            this.currentStack.stepTo(1);
        });

        this.touch.events.on("swipeleft", () => {
            console.log("swipeleft");
            this.translateToNextStack();
        });

        this.touch.events.on("swiperight", () => {
            console.log("swiperight");
            this.translateToPreviousStack();
        });

        //mouse swipe
        this.mouse.events.on("swiperight", () => {
            console.log("swiperight");
            this.translateToPreviousStack();
        });
        this.mouse.events.on("swipeleft", () => {
            console.log("swipeleft");
            this.translateToNextStack();
        });

        //desktop interaction with current stack
        this.mouse.events.on("mousewheel", (x, y) => {
            //console.log(x,y);

            if (!this.lockMouseWheelY) {

                if (y > this.stackYSwipeLimit) {
                    this.currentStack.stepTo(1);
                    this.lockMouseWheelY = true;
                }
                if (y < -this.stackYSwipeLimit) {
                    this.currentStack.stepTo(-1);
                    this.lockMouseWheelY = true;
                }

                if (this.lockMouseWheelY) {
                    setTimeout(() => {
                        this.lockMouseWheelY = false;
                    }, 400);
                }
            }

            if (!this.lockMouseWheelX) {

                if (x > this.stackXSwipeLimit) {
                    this.translateToNextStack();
                    this.lockMouseWheelX = true;
                }
                if (x < -this.stackXSwipeLimit) {
                    this.translateToPreviousStack();
                    this.lockMouseWheelX = true;
                }
            }

            if (this.lockMouseWheelX) {
                if (x < 10 && x > -10) {
                    this.lockMouseWheelX = false;
                    //console.log("unlock x")
                }
            }
        });

        //keyboard interaction
        this.keyboard.events.on("keydown", (event) => {
            //console.log(event);
            if (event.key === "ArrowUp" || event.code === "ArrowUp") {
                this.currentStack.stepTo(-1);
            }
            else if (event.key === "ArrowDown" || event.code === "ArrowDown") {
                this.currentStack.stepTo(1);
            }
            else if (event.key === "ArrowRight" || event.code === "ArrowRight") {
                this.translateToNextStack();
            }
            else if (event.key === "ArrowLeft" || event.code === "ArrowLeft") {
                this.translateToPreviousStack();
            }
        });
    }

    translateToNextStack() {
        this.currentStackIndex++;
        this.currentStackIndex %= this.stacks.length;

        this.currentStack = this.stacks[this.currentStackIndex];

        //console.log("currentStackIndex", this.currentStackIndex, this.currentStack.transform.getLocalPositionX());
    }

    translateToPreviousStack() {
        this.currentStackIndex--;
        if (this.currentStackIndex < 0) {
            this.currentStackIndex = this.stacks.length - 1;
        }

        this.currentStack = this.stacks[this.currentStackIndex];

        //console.log("currentStackIndex", this.currentStackIndex, this.currentStack.transform.getLocalPositionX());
    }



    // refresh loop
    update() {
        //translation effect from a stack to the other
        const x = this.currentStack.transform.getLocalPositionX();
        const camX = this.camera.transform.getLocalPositionX();
        const newX = Mobilizing.math.lerp(camX, x, 0.05);
        this.camera.transform.setLocalPositionX(newX);
    }
}
