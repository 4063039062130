import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {

        this.bufferRequest1 = loader.loadArrayBuffer({ url: "./assets/audio/harp1.wav" });
        this.bufferRequest2 = loader.loadArrayBuffer({ url: "./assets/audio/harp2.wav" });
    }

    setup() {

        this.audioRenderer = new Mobilizing.audio.Renderer();
        this.context.addComponent(this.audioRenderer);

        this.threeRenderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.threeRenderer);

        this.camera = new Mobilizing.three.Camera();
        this.threeRenderer.addCamera(this.camera);
        this.camera.setToPixel(); //adapt automatically the camera to the window

        const size = this.threeRenderer.getCanvasSize();

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(size.width / 2, -(size.height / 2), 1000);
        light.setDistance(10000);
        this.threeRenderer.addToCurrentScene(light);

        const posBase = 200;

        const circle = new Mobilizing.three.Circle({ "radius": 20 });
        circle.transform.setLocalPosition(posBase, -posBase, 0);
        this.threeRenderer.addToCurrentScene(circle);

        const circle2 = new Mobilizing.three.Circle({ "radius": 20 });
        circle2.transform.setLocalPosition(size.width - posBase, -(size.height - posBase), 0);
        this.threeRenderer.addToCurrentScene(circle2);

        this.listener = new Mobilizing.three.Octahedron({ radius: 20, detail: 2 });
        this.listener.transform.setLocalPosition(size.width / 2, -(size.height / 2), 0);
        //think about the listener orientation! Where it points in space will configure the panning!
        this.listener.transform.setLocalRotationY(180);
        this.threeRenderer.addToCurrentScene(this.listener);

        //audio
        this.audioRenderer.setListenerTransform(this.listener.transform);

        const source = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true,
            "loop": true
        });
        this.context.addComponent(source);
        source.setRefDistance(100);

        const sound = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest1.getValue(),
            "decodedCallback": () => {
                source.setBuffer(sound);
            }
        });

        const source2 = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true,
            "loop": true
        });
        this.context.addComponent(source2);
        source2.setRefDistance(100);

        const sound2 = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest2.getValue(),
            "decodedCallback": () => {
                source2.setBuffer(sound2);
            }
        });

        source.setTransform(circle.transform);
        source2.setTransform(circle2.transform);

        //inputs

        const touch = this.context.addComponent(new Mobilizing.input.Touch({ "target": this.threeRenderer.canvas }));
        touch.setup();//set it up
        touch.on();//active it

        const mouse = this.context.addComponent(new Mobilizing.input.Mouse({ "target": this.threeRenderer.canvas }));
        mouse.setup();//set it up
        mouse.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(touch);
        this.pointer.add(mouse);
        this.pointer.setup();//set it up
        this.pointer.on();//active it

        //we must play sounds on user action!
        this.pointer.events.on("on", (event) => {

            if (!source.playing) {
                source.play();
            }
            if (!source2.playing) {
                source2.play();
            }
        });
    }

    update() {
        //console.log(this.pointer.pointers);
        if (this.pointer.getState()) {

            this.listener.transform.setLocalPosition(
                this.pointer.getX(0),
                - this.pointer.getY(0),
                0);
        }

        //this.listener.transform.setLocalRotationY(this.listener.transform.getLocalRotationY() + 1);
    }

}
