import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        this.needsUserInteraction = true;
        this.x = 0;
        this.xSpeed = 0.1;
    }

    preLoad(loader) {
        this.bufferRequest1 = loader.loadArrayBuffer({ url: "./assets/audio/harp1.wav" });
    }

    setup() {

        this.audioRenderer = new Mobilizing.audio.Renderer();
        this.context.addComponent(this.audioRenderer);
        this.audioRenderer.setup();

        this.threeRenderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.threeRenderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPositionZ(30);
        this.threeRenderer.addCamera(this.camera);

        //const size = this.threeRenderer.getCanvasSize();

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(0, 10, 20);
        this.threeRenderer.addToCurrentScene(light);

        this.circle = new Mobilizing.three.Circle();
        this.threeRenderer.addToCurrentScene(this.circle);

        //audio
        const source = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D" : true,
            "loop" : true
        });
        this.context.addComponent(source);
        source.setRefDistance(5);

        const sound = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest1.getValue(),
            "decodedCallback": () => {
                source.setBuffer(sound);
            }
        });

        source.setTransform(this.circle.transform);

        //inputs

        const touch = this.context.addComponent(new Mobilizing.input.Touch({ "target": this.threeRenderer.canvas }));
        touch.setup();//set it up
        touch.on();//active it

        const mouse = this.context.addComponent(new Mobilizing.input.Mouse({ "target": this.threeRenderer.canvas }));
        mouse.setup();//set it up
        mouse.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(touch);
        this.pointer.add(mouse);

        setTimeout( () => {
            if (!source.playing) {
                source.play();
            }
        }, 100);
    }

    update() {
        if (this.x < -20 || this.x > 20) {
            this.xSpeed = -this.xSpeed;
        }

        this.x += this.xSpeed;
        this.circle.transform.setLocalPositionX(this.x);
    }
}
