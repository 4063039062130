import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {
        //we will need access to all loaded images in the setup
        this.sequenceRequests = [];

        //loads all images as requests
        const imgTotal = 18;
        for (let i = 1; i <= imgTotal; i++) {
            const imgRequest = loader.loadImage({ "url": "./assets/sequence/jumping-girl/anime" + i + ".png" });
            this.sequenceRequests.push(imgRequest);
        }
    }

    setup() {

        this.zStep = 200;
        this.camZStart = 400;
        this.camZEnd = this.camZStart - (this.zStep * this.sequenceRequests.length + this.camZStart);
        console.log(this.camZStart, this.camZEnd);

        //We take advantage of the Basic3DContext class to get a predefined scene
        Mobilizing.Basic3DContext.makeBasicContext(this.context);
        this.context.camera.setFOV(100);
        this.context.camera.transform.setLocalPositionZ(200);

        //inputs
        this.accel = new Mobilizing.input.Motion();
        this.accel = this.context.addComponent(this.accel);
        this.accel.setup();//set it up
        this.accel.on();

        //a mesh group root
        this.root = new Mobilizing.three.Node();
        this.context.renderer.addToCurrentScene(this.root);

        //build the 3D planes to map texture on
        for (let i = 0; i < this.sequenceRequests.length; i++) {
            //get the current image
            const img = this.sequenceRequests[i].getValue();
            //build a texture from the image
            const texture = new Mobilizing.three.Texture({ "image": img });
            //build a plane
            const plane = new Mobilizing.three.Plane({
                "width": img.width,
                "height": img.height,
                "material": "basic"
            });
            //map the texture on it
            plane.material.setTexture(texture);
            //add it the the root
            this.root.transform.addChild(plane.transform);

            plane.transform.setLocalPositionZ(i * -this.zStep);
        }
    }

    update() {

        const smoothedAcc = this.accel.getSmoothedAcc(0.07);
        const mappedzPos = Mobilizing.math.map(smoothedAcc.z, 3,-10, this.camZStart, this.camZEnd);
        const zPos = Mobilizing.math.clamp(mappedzPos, this.camZEnd, this.camZStart) ;
        this.context.camera.transform.setLocalPositionZ(zPos);

    }
}
