import * as Mobilizing from '@mobilizing/library';

export function takeScreenShot(renderer) {

    if (Mobilizing.getUrlParameter("screenshot")) {

        //console.log("screenshot to take for", window.document.title);
        window.addEventListener("keydown", (event) => {

            const keyCode = String.fromCharCode(event.keyCode).toLowerCase();
            if (keyCode === "s") {
                console.log("saving from", renderer, "to", window.document.title);
                window.innerWidth = 600;
                window.innerHeight = 800;
                renderer.saveImageAs(`${window.document.title}.jpg`);
            }
        })
    }
}
