import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;

        this.camZ = 350;
        this.zStep = 400;
    }

    preLoad(loader) {
        //we will need access to all loaded images in the setup
        this.sequenceRequests = [];

        //loads all images as requests
        const imgTotal = 6;
        for (let i = 0; i <= imgTotal; i++) {
            const imgRequest = loader.loadImage({ "url": "./assets/sequence/Pisan/pisan" + i + ".png" });
            this.sequenceRequests.push(imgRequest);
        }
    }

    setup() {

        //make a rendrer
        this.renderer = new Mobilizing.three.RendererThree();
        this.renderer.setClearColor(Mobilizing.three.Color.darkGray);
        this.context.addComponent(this.renderer);

        //make a camera
        this.camera = new Mobilizing.three.Camera();
        this.camera.setFarPlane(100000);
        this.camera.setFOV(100);
        this.camera.transform.setLocalPositionZ(this.camZ);
        this.renderer.addCamera(this.camera);

        //inputs
        this.orientation = new Mobilizing.input.Orientation();
        this.context.addComponent(this.orientation);
        this.orientation.setup();
        this.orientation.on();

        this.accel = new Mobilizing.input.Motion();
        this.accel = this.context.addComponent(this.accel);
        this.accel.setup();
        //this.renderer.getCanvas().addEventListener("click", () => {
        this.accel.on();
        //});

        //a mesh group root
        this.root = new Mobilizing.three.Node();
        this.renderer.addToCurrentScene(this.root);

        //build the 3D planes to map texture on
        for (let i = 0; i < this.sequenceRequests.length; i++) {
            //get the current image
            const img = this.sequenceRequests[i].getValue();
            //build a texture from the image
            const texture = new Mobilizing.three.Texture({ "image": img });
            //build a plane
            const plane = new Mobilizing.three.Plane({
                "width": img.width,
                "height": img.height,
                "material": "basic"
            });
            //map the texture on it
            plane.material.setTexture(texture);
            plane.material.setTransparent(true);
            //add it the the root
            this.root.transform.addChild(plane.transform);

            plane.transform.setLocalPositionZ(this.zStep * -i);
            plane.transform.setLocalScale(i + 1, i + 1, 1);
        }

        this.orientation.resetDirection();

        window.addEventListener("pointerup", () => {
            this.orientation.resetDirection();
        });
    }

    update() {
        const smoothedAcc = this.accel.getSmoothedAcc(0.07);
        const mappedzPos = Mobilizing.math.map(smoothedAcc.z, 3, -10, -100, 100);

        this.camera.transform.setLocalPositionY(mappedzPos);

        this.camera.transform.setLocalPositionX(this.orientation.rotationMatrix[3] * 100);
        this.camera.lookAt(new Mobilizing.three.Vector3(0, 0, 0));
    }
}
