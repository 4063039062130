import * as Mobilizing from '@mobilizing/library';

export class Script {


    preLoad(loader) {
        this.fontFile = loader.loadArrayBuffer({ url: "./assets/fonts/Menlo-Regular.ttf" });
        console.log(this.fontFile);
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera({ "type": "ortho" });
        this.camera.transform.setLocalPositionZ(1);
        this.renderer.addCamera(this.camera);

        this.light = new Mobilizing.three.Light();
        this.light.transform.setLocalPosition(50, 0, 100);
        this.renderer.addToCurrentScene(this.light);

        //setup what we need to have touch/mouse
        this.mouse = new Mobilizing.input.Mouse({
            "target": this.renderer.canvas
        });
        this.context.addComponent(this.mouse);
        this.mouse.setup();
        this.mouse.on();//active it

        this.touch = new Mobilizing.input.Touch({
            "target": this.renderer.canvas
        });
        this.context.addComponent(this.touch);
        this.touch.setup();
        this.touch.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(this.mouse);
        this.pointer.add(this.touch);
        this.pointer.setup();

        const fontBuffer = this.fontFile.getValue();
        this.font = new Mobilizing.Font({ fontFile: fontBuffer });

        let keys = "1234567890";
        keys += "azertyuiop";
        keys += "qsdfghjklm";
        keys += "wxcvbn,;:=";
        keys += "+-()$%←→↵⌫ ";
        const keyboardButtons = [];

        for (let i = 0; i < keys.length; i++) {

            const button = new Mobilizing.three.Button({
                "camera": this.camera,
                "pointer": this.pointer,
                "radius": 30,
                "strokeWidth": 4,
                "font": this.font,
                "text": keys.charAt(i),
                "textSize": 70,

                "onPress": function () {
                    console.log("you clicked me!", this);
                }
            });

            button.onPress = function () {
                console.log("clicked on", button.text);
                this.addNewLetter(button.text);
            }.bind(this);

            this.context.addComponent(button);
            button.setup();

            keyboardButtons.push(button);
        }

        //organize them as a grid
        const grid1 = new Mobilizing.three.ButtonGroup({
            "buttons": keyboardButtons,
            "columns": 10,
            "mode": "honeycomb"
        });
        this.renderer.addToCurrentScene(grid1.root);

        //position it in the middle of the screen
        grid1.transform.setLocalPosition(-grid1.width / 2, -this.renderer.getCanvasHalfHeight() + grid1.height, 0);

        //**TextField**/
        this.textField = new Mobilizing.TextField({
            "font": this.font
        });

        this.textFieldDisplay = new Mobilizing.three.Plane({
            "width": this.textField.width,
            "height": this.textField.height,
            "material": "basic"
        });
        this.renderer.addToCurrentScene(this.textFieldDisplay);
        this.textFieldDisplay.transform.setLocalPositionY(this.renderer.getCanvasHalfHeight() - this.textField.height);

        this.textFieldTexture = new Mobilizing.three.Texture({
            "canvas": this.textField.getCanvas()
        });
        this.textFieldDisplay.material.setTexture(this.textFieldTexture);

        //Use the pointer to project a ray in the 3D object, then pick it to get UV coordinates, and finally convert this coords to canvas 2D space
        this.pointer.events.on("on", (pointer) => {

            //3D object transform based picking (return an object)
            const pick = this.textFieldDisplay.transform.pick(this.camera, pointer.x, pointer.y);

            //we picked something!
            if (pick) {
                //map the 3D object size to the canvas 2D space
                const mappedPick = new Mobilizing.three.Vector2();
                const worldPos = this.textFieldDisplay.transform.getWorldPosition();
                mappedPick.x = pick.point.x - worldPos.x + this.textFieldDisplay.width / 2;
                mappedPick.y = this.textFieldDisplay.height - (pick.point.y - worldPos.y + this.textFieldDisplay.height / 2);
                console.log(mappedPick);

                let letterPicked = this.textField.pickLetter(mappedPick.x, mappedPick.y);
                //we picked a letter in the canvas!
                if (letterPicked) {
                    this.textField.moveCursorTo(letterPicked.index);
                }
            }

        });
    }

    addNewLetter(key) {
        //if we pressed something
        if (key.length) {
            //magage special keys first
            if (key === "←" ||
                key === "→" ||
                key === "↵" ||
                key === "⌫") {
                switch (key) {
                    case '←':
                        this.textField.moveCursorBack();
                        break;

                    case '→':
                        this.textField.moveCursorForward();
                        break;

                    case '↵':
                        console.log("isEnter");
                        this.textField.addLetter("\n");
                        break;

                    case '⌫':
                        console.log("isDelete");
                        this.textField.delete();
                        break;
                }
            }
            else {
                //or simply add it to the textfield
                this.textField.addLetter(key);
            }
        }
    }

    update() {

    }
}
