
import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPosition(0, 0, 100);
        this.renderer.addCamera(this.camera);

        this.light = new Mobilizing.three.Light();
        this.light.transform.setLocalPosition(50, 0, 100);
        this.renderer.addToCurrentScene(this.light);

        const mesh = new Mobilizing.three.Box({
            "width" : 20,
            "height" : 30,
            "depth" : 20
        });

        mesh.transform.setLocalRotation(33, 43, 0);
        mesh.setScale(.1,-1,2);
        this.renderer.addToCurrentScene(mesh);
    }

    update() {

    }
}
