import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
    }

    preLoad(loader) {
        this.spriteFile = loader.loadImage({ url: "./assets/img/sprite.png" });
    }

    setup() {
        this.renderer = new Mobilizing.three.RendererThree();

        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);
        this.camera.setToPixel(); //adapt automatically the camera to the window

        const size = this.renderer.getCanvasSize();

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(size.width / 2, -(size.height / 2), 1000);
        light.setDistance(10000);
        this.renderer.addToCurrentScene(light);

        //inputs
        const touch = this.context.addComponent(new Mobilizing.input.Touch({ "target": this.renderer.canvas }));
        touch.setup();//set it up
        touch.on();//active it

        const mouse = this.context.addComponent(new Mobilizing.input.Mouse({ "target": this.renderer.canvas }));
        mouse.setup();//set it up
        mouse.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(touch);
        this.pointer.add(mouse);
        this.pointer.setup();//set it up
        this.pointer.on();//active it

        this.spriteTexture = new Mobilizing.three.Texture({ image: this.spriteFile.getValue() });
    }

    update() {
        if (this.pointer.getState()) {
            //make a new sprite
            const point = new Mobilizing.three.Sprite();
            point.material.setTexture(this.spriteTexture);
            point.material.setColor(Mobilizing.three.Color.random());
            point.transform.setLocalPosition(this.pointer.getX(), -this.pointer.getY());
            point.transform.setLocalScale(50);
            this.renderer.addToCurrentScene(point);
        }
    }
}
