import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
        this.z = 0;
        this.zSpeed = 0.1;
    }

    preLoad(loader) {

        this.bufferRequest1 = loader.loadArrayBuffer({ url: "./assets/audio/harp1.wav" });
        this.bufferRequest2 = loader.loadArrayBuffer({ url: "./assets/audio/harp2.wav" });
    }

    setup() {

        this.audioRenderer = new Mobilizing.audio.Renderer();
        this.context.addComponent(this.audioRenderer);

        this.threeRenderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.threeRenderer);

        this.camera = new Mobilizing.three.Camera();
        this.threeRenderer.addCamera(this.camera);
        this.camera.transform.setLocalPositionZ(30);

        this.audioRenderer.setListenerTransform(this.camera.transform);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(0, 50, 100);
        this.threeRenderer.addToCurrentScene(light);

        const circle = new Mobilizing.three.Circle();
        circle.transform.setLocalPosition(-10, 0, 50);
        this.threeRenderer.addToCurrentScene(circle);

        const circle2 = new Mobilizing.three.Circle();
        circle2.transform.setLocalPosition(10, 0, -50);
        this.threeRenderer.addToCurrentScene(circle2);

        //audio
        const source = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true,
            "loop": true
        });
        this.context.addComponent(source);
        source.setRefDistance(100);

        const sound = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest1.getValue(),
            "decodedCallback": () => {
                source.setBuffer(sound);
                if (!source.playing) {
                    source.play();
                }
            }
        });

        const source2 = new Mobilizing.audio.Source({
            "renderer": this.audioRenderer,
            "is3D": true,
            "loop": true
        });
        this.context.addComponent(source2);
        source2.setRefDistance(100);

        const sound2 = new Mobilizing.audio.Buffer({
            "renderer": this.audioRenderer,
            "arrayBuffer": this.bufferRequest2.getValue(),
            "decodedCallback": () => {
                source2.setBuffer(sound2);
                if (!source2.playing) {
                    source2.play();
                }
            }
        });

        source.setTransform(circle.transform);
        source2.setTransform(circle2.transform);

    }

    update() {
        if (this.z < -100 || this.z > 100) {
            this.zSpeed = -this.zSpeed;
        }

        this.z += this.zSpeed;
        this.camera.transform.setLocalPositionZ(this.z);
    }
}
