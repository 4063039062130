import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.setFOV(30);
        this.camera.setToPixel(); //adapt automatically the camera to the window
        this.renderer.addCamera(this.camera);

        //inputs
        const touch = this.context.addComponent(new Mobilizing.input.Touch({ "target": this.renderer.canvas }));
        touch.setup();//set it up
        touch.on();//active it

        this.mouse = this.context.addComponent(new Mobilizing.input.Mouse({ "target": this.renderer.canvas }));
        this.mouse.setup();//set it up
        this.mouse.on();//active it

        this.pointer = new Mobilizing.input.Pointer();
        this.context.addComponent(this.pointer);
        this.pointer.add(touch);
        this.pointer.add(this.mouse);
        this.pointer.setup();//set it up
        this.pointer.on();//active it

        //scene construction
        let size = this.renderer.getCanvasSize();

        this.light = new Mobilizing.three.Light({ type: "spot" });
        this.light.transform.setLocalPosition(size.width / 2, -size.height / 2, 1000);
        this.light.setTargetPosition(size.width / 2, -size.height / 2, 0);
        this.light.setAngle(Math.PI / 20);
        this.light.setPenumbra(.5);
        this.light.setIntensity(1.5);
        this.renderer.addToCurrentScene(this.light);

        //Build a Node, that is an empty object that can be used to create groups
        //a node has no geometry but can have many children, grouped together
        this.node = new Mobilizing.three.Node();
        this.node.transform.setLocalPosition(size.width / 2, -size.height / 2);
        this.renderer.addToCurrentScene(this.node);

        for (let i = 0; i < 200; i++) {
            let temp = new Mobilizing.three.Cube();
            temp.transform.setLocalPosition(Mobilizing.math.randomFromTo(-10, 10),
                Mobilizing.math.randomFromTo(-10, 10),
                Mobilizing.math.randomFromTo(-10, 10));
            temp.transform.setLocalScale(3);
            this.node.transform.addChild(temp.transform);
        }
        this.node.transform.setLocalScale(30);

        this.renderer.setFog("linear");
        this.renderer.setFogNear(this.node.getBoundingSphere().radius);
        this.renderer.setFogFar(Math.abs(this.camera.transform.getLocalPositionZ()) * 1.5);
    }

    update() {
        if (this.pointer.getState()) {
            this.light.transform.setLocalPosition(this.pointer.getX(), - this.pointer.getY());

            let lightPos = this.light.transform.getLocalPosition();
            this.light.setTargetPosition(lightPos.x, lightPos.y, 0);
        }

        //this.light.transform.setLocalPosition(this.mouse.getX(), - this.mouse.getY());

        let lightPos = this.light.transform.getLocalPosition();
        this.light.setTargetPosition(lightPos.x, lightPos.y, 0);

        this.node.transform.setLocalRotationY(this.node.transform.getLocalRotationY() + .1);
    }
}
