import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);
        this.camera.transform.setLocalPositionZ(20);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(10, 10, 10);
        this.renderer.addToCurrentScene(light);

        this.accel = new Mobilizing.input.Motion();
        this.accel = this.context.addComponent(this.accel);
        this.accel.setup();//set it up

        this.accel.on();//active it

        this.accel.events.on("accelerationGravityVector", this.accelEvent.bind(this));

        this.brick = new Mobilizing.three.Box({
            "width": 2,
            "height": 2,
            "depth": .5
        });
        this.brick.transform.setLocalPosition(0, 2, 0);
        this.renderer.addToCurrentScene(this.brick);

        this.brick2 = new Mobilizing.three.Box({
            "width": 2,
            "height": 2,
            "depth": .5
        });
        this.brick2.transform.setLocalPosition(0, -2, 0);
        this.renderer.addToCurrentScene(this.brick2);
    }

    accelEvent(acc) {

        this.brick.transform.setLocalRotationOrder("ZXY");
        this.brick.transform.setLocalRotation(acc.z, -acc.x, -acc.y + 180);

        this.brick2.transform.setLocalRotationZ(-acc.y + 180);
    }

    update() {

    }
}
