import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
    }

    //preload the necessary ressources for this script
    preLoad(loader) {
        this.imageRequest = loader.loadImage({ "url": "./assets/img/mire.jpg" });
    }

    //initialize the components we need and build the scene
    setup() {
        //the 3D renderer
        this.renderer = new Mobilizing.three.RendererThree({
            "antialias": true
        });
        this.context.addComponent(this.renderer);
        //a camera to see the world
        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPositionZ(5);
        this.renderer.addCamera(this.camera);
        //get the loaded request content
        const image = this.imageRequest.getValue();
        //build a cube
        const cube = new Mobilizing.three.Cube({
            "material": "basic",
            "segments": 8
        });
        //build a texture and apply it to the cube
        const texture = new Mobilizing.three.Texture({ "image": image });
        cube.material.setTexture(texture);
        //add it to the scene and transform it
        this.renderer.addToCurrentScene(cube);
        cube.transform.setLocalRotation(33, 0, 33);
    }

    update() {
        //refresh loop, unused here
    }
}
