/**
 * Mobilizing.js is NOT a 3D renderer by itself, it has a 3D renderer entierly built upon Three.js. If you want to use some part of Three.js directly, you can! The binding between Mobilizing.js and Three.js is not possible for everything, but here's how to do it for geometries.
 */

import * as Mobilizing from '@mobilizing/library';
//Three.js is a dependency of Mobilizing, so you can import it anytime!
const THREE = Mobilizing.THREE;

export class Script {

    constructor() {
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree({});
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPosition(0, 0, 100);
        this.renderer.addCamera(this.camera);

        this.light = new Mobilizing.three.Light();
        this.light.transform.setLocalPosition(50, 0, 100);
        this.renderer.addToCurrentScene(this.light);

        //generates the points (vertices) we want to use for the custom geometry in Three.js
        const points = [];
        for (let i = 0; i < 10; i++) {
            points.push(new Mobilizing.three.Vector2(Math.sin(i * 0.2) * 10 + 5, (i - 5) * 2));
        }
        const latheGeometry = new THREE.LatheGeometry(points, 32);

        //use the Three geometry to create the Mobilizing compiliant Mesh 
        const mesh = new Mobilizing.three.Mesh({
            "geometry": latheGeometry
        });
        mesh.material.setShading("smooth");
        mesh.transform.setLocalRotation(45, 33, 0);
        this.renderer.addToCurrentScene(mesh);
    }

    update() {

    }
}
