/**
 * Mobilizing.js is NOT a 3D renderer by itself, it has a 3D renderer entierly built upon Three.js. If you want to use some part of Three.js directly, you can! The binding between Mobilizing.js and Three.js is not possible for everything, but here's how to do it for materials.
 */

import * as Mobilizing from '@mobilizing/library';
//Three.js is a dependency of Mobilizing, so you can import it anytime!
const THREE = Mobilizing.THREE;

export class Script {

    constructor() {
        this.y = 0;
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree({
            /* "antialias": true */
        });
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.camera.transform.setLocalPosition(0, 0, 10);
        this.renderer.addCamera(this.camera);

        //the mesh will be colored according to the geometry normals
        const normalMaterial = new THREE.MeshNormalMaterial();
        this.mesh = new Mobilizing.three.Plane({
            "segments": 20,
            "width": 5,
            "height": 5,
            "material": normalMaterial
        });

        //change the vertices of the plane geometry
        const meshVertices = this.mesh.getVertices();
        console.log(meshVertices);
        for (let i = 0; i < meshVertices.length; i++) {
            const vertex = meshVertices[i];
            vertex.z = Mobilizing.math.randomFromTo(-1, 1);
            console.log(vertex);
        }
        this.mesh.setVertices(meshVertices);

        this.mesh.transform.setLocalRotation(33, 33, 0);
        this.renderer.addToCurrentScene(this.mesh);
    }

    update() {
        this.y += 0.1;
        this.mesh.transform.setLocalRotationY(this.y);
    }
}
