import * as Mobilizing from '@mobilizing/library';

export class Script {

    /* constructor() {
    } */

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(100, 100, 100);
        this.renderer.addToCurrentScene(light);

        this.width = 32;
        this.height = 32;

        this.camera.transform.setLocalPosition(this.width / 2 * 2, -this.height / 2, 50);

        //we MUST define a callback to grab the mediaStream from the webcam
        //also, we simply use the specification of getUserMedia, so check it to know the constraints
        this.webcam = new Mobilizing.input.UserMedia({
            deviceKind: "videoinput",
            callback: this.webcamCallback.bind(this),
            //useNativeResolution: true,
            constraints: { video: { "width": this.width, "height": this.height } }
        });

        this.context.addComponent(this.webcam);
        this.webcam.setup();
        this.webcam.on();

        //make as many boxes as the nb of frequencies we analyse
        const size = 0.8;
        this.boxes = [];
        for (let x = 0; x < this.width; x++) {
            for (let y = 0; y < this.height; y++) {
                const box = new Mobilizing.three.Box({ width: size, height: size, length: size });
                this.renderer.addToCurrentScene(box);
                box.transform.setLocalPosition(x, -y);
                this.boxes.push(box);
            }
        }

        this.camera.transform.lookAt(new Mobilizing.three.Vector3(this.width / 2, -this.height / 2, 0));
    }

    //when the usermedia is ready to be used, it gives a MediaStream to work with
    webcamCallback(media) {
        this.cameraIsReady = true;
        console.log("media", media, this.webcam.canvas);
        /* document.body.appendChild(this.webcam.canvas);
        this.webcam.canvas.style.position = "absolute";
        this.webcam.canvas.style.top = "0px"; */
    }

    update() {

        if (this.cameraIsReady) {
            const drawContext = this.webcam.canvas.getContext("2d");
            drawContext.drawImage(this.webcam.videoEl, 0, 0);

            let index = 0;

            for (let x = 0; x < this.width; x++) {
                for (let y = 0; y < this.height; y++) {
                    const pixel = drawContext.getImageData(x, y, 1, 1);
                    const data = pixel.data;
                    this.boxes[index].material.setColor(new Mobilizing.three.Color(data[0] / 255, data[1] / 255, data[2] / 255));
                    //convert to grayscale/luminance
                    const gray = data[0] * 0.3 + data[1] * 0.6 + data[2] * 0.11;
                    this.boxes[index].transform.setLocalScaleZ(gray / 10);

                    index++;
                }
            }
        }
    }
}
