import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        this.shapes = [];
        this.rotX = 0;
        this.rotY = 0;
    }

    preLoad(loader) {

    }

    setup() {
        this.renderer = new Mobilizing.three.RendererThree();

        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);
        this.camera.transform.setLocalPositionZ(20);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(10, 10, 10);
        this.renderer.addToCurrentScene(light);

        let posX = 0;
        let posY = 0;
        const step = 3;

        const cubePhong = new Mobilizing.three.Box({ "material": "phong" });
        cubePhong.transform.setLocalPosition(posX, posY, 0);
        this.shapes.push(cubePhong);

        const cubeLambert = new Mobilizing.three.Box({ "material": "lambert" });
        cubeLambert.transform.setLocalPosition(posX += step, posY, 0);
        this.shapes.push(cubeLambert);

        const cubeBasic = new Mobilizing.three.Box({ "material": "basic" });
        cubeBasic.material.setColor(new Mobilizing.three.Color(1, .1, .2));
        cubeBasic.transform.setLocalPosition(posX = 0, posY -= step, 0);
        this.shapes.push(cubeBasic);

        const spherePhong = new Mobilizing.three.Sphere({ "material": "phong", "segments": 32 });
        spherePhong.material.setShading("smooth");
        spherePhong.transform.setLocalPosition(posX += step, posY, 0);
        this.shapes.push(spherePhong);

        const spherePhongFlat = new Mobilizing.three.Sphere({ "material": "phong", "segments": 32 });
        spherePhongFlat.transform.setLocalPosition(posX = 0, posY -= step, 0);
        this.shapes.push(spherePhongFlat);

        const sphereLambert = new Mobilizing.three.Sphere({ "material": "lambert", "segments": 32 });
        sphereLambert.material.setShading("smooth");
        sphereLambert.transform.setLocalPosition(posX += step, posY, 0);
        this.shapes.push(sphereLambert);

        const sphereLambertFlat = new Mobilizing.three.Sphere({ "material": "lambert", "segments": 32 });
        sphereLambertFlat.transform.setLocalPosition(posX = 0, posY -= step, 0);
        this.shapes.push(sphereLambertFlat);

        this.shapes.forEach(shape => {
            this.renderer.addToCurrentScene(shape);
        });

        // position the camera to the center of the objects
        this.camera.transform.setLocalPosition(posX / 2 + 1, posY / 2);
    }

    update() {
        this.rotX += 0.1;
        this.rotY += 0.5;

        this.shapes.forEach(shape => {
            shape.transform.setLocalRotation(this.rotX, 20, this.rotY);
        });
    }
}
