import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {
        //we will need access to all loaded images in the setup
        this.sequenceRequests = [];

        //loads all images as requests
        const imgTotal = 18;
        for (let i = 1; i <= imgTotal; i++) {
            const imgRequest = loader.loadImage({ "url": "./assets/sequence/jumping-girl/anime" + i + ".png" });
            this.sequenceRequests.push(imgRequest);
        }
    }

    setup() {

        //make a rendrer
        this.renderer = new Mobilizing.three.RendererThree();
        this.renderer.setClearColor(Mobilizing.three.Color.darkGray);
        this.context.addComponent(this.renderer);

        //make a camera
        this.camera = new Mobilizing.three.Camera();
        this.camera.setFOV(30);
        this.renderer.addCamera(this.camera);

        //make a light
        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(100, 100, 100);
        this.renderer.addToCurrentScene(light);

        //inputs
        this.orientation = new Mobilizing.input.Orientation();
        this.context.addComponent(this.orientation);
        this.orientation.setup();
        this.orientation.on();

        //a mesh group root
        this.root = new Mobilizing.three.Node();
        this.renderer.addToCurrentScene(this.root);

        //compute the size of one "pie" piece
        const degreesStep = 360 / this.sequenceRequests.length;
        const radius = 550 * 3;

        //build the 3D planes to map texture on
        for (let i = 0; i < this.sequenceRequests.length; i++) {
            //get the current image
            const img = this.sequenceRequests[i].getValue();
            //build a texture from the image
            const texture = new Mobilizing.three.Texture({ "image": img });
            //build a plane
            const plane = new Mobilizing.three.Plane({
                "width": img.width,
                "height": img.height,
                "material": "basic"
            });
            //map the texture on it
            plane.material.setTexture(texture);
            //add it the the root
            this.root.transform.addChild(plane.transform);

            //compute de x, z local position
            const radians = (degreesStep * i) * Math.PI / 180;
            const x = Math.cos(radians) * radius;
            const z = Math.sin(radians) * radius;

            //compute the local Y rotation
            const localDegree = (degreesStep * -i) - 90;
            
            plane.transform.setLocalRotationY(localDegree);
            plane.transform.setLocalPosition(x, 0, z);
        }
        //quaternion to rotate the camera with gyro heading
        this.gyroQuat = new Mobilizing.three.Quaternion();
    }

    update() {
        //apply quaternion to camera
        this.gyroQuat.setFromGyro(this.orientation.compass);
        if (this.gyroQuat) {
            this.camera.transform.setLocalQuaternion(this.gyroQuat);
        }
    }
}
