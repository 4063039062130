import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //This is the map bounds given by TileMill & MapBox when exporting to picture file
        this.mapBounds = [-180, -85.0511, 180, 85.0511];//[4.8393, 44.7998, 5.128, 45.0042];
    }

    preLoad(loader) {
        this.mapFile = loader.loadImage({ "url": "./assets/img/world.png" });
        //this.liveMap = loader.loadBlob({ "url": "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/[4.8393,44.7998,5.128,45.0042]/1024x1024?access_token=pk.eyJ1IjoiZG9taW5pcXVlY3VuaW4iLCJhIjoiY2l0anRzMjhoMDAyMTRicW5tbnN1Z2E2biJ9.BnjwoJgcNd7S5OE74G-H0g" });
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);
        this.camera.transform.setLocalPositionZ(3500);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(500, 1000, 500);
        this.renderer.addToCurrentScene(light);

        const GPS = this.context.addComponent(new Mobilizing.input.GPS({ "target": this.renderer.canvas }));
        GPS.setup();//set it up
        GPS.on();//active it
        GPS.events.on("locationupdated", this.newLocation.bind(this));

        this.mapImg = this.mapFile.getValue();
        const texture = new Mobilizing.three.Texture({ "image": this.mapImg });

        /* const img = new Image();
        img.src = URL.createObjectURL(this.liveMap.getValue());
        const texture = new Mobilizing.three.Texture({ "image": img });*/

        //make a plan
        const plane = new Mobilizing.three.Plane({
            "width": this.mapImg.width,
            "height": this.mapImg.height,
            "material": "basic"
        });
        plane.material.setTexture(texture);
        this.renderer.addToCurrentScene(plane);

        //you're here tag
        const radius = 10;
        this.hereTag = new Mobilizing.three.Circle({ radius, "material": "basic" });
        this.hereTag.transform.setLocalPosition(-radius / 2, radius / 2, 10);
        this.hereTag.material.setColor(Mobilizing.three.Color.red.clone());
        plane.transform.addChild(this.hereTag.transform);
    }

    newLocation(location) {

        const mapfactor = Mobilizing.input.GPSUtils.getCoordinateInMap(
            location.longitude,
            location.latitude,
            this.mapBounds,
            this.mapImg.width,
            this.mapImg.height
        );

        this.hereTag.transform.setLocalPositionX(mapfactor.x);
        this.hereTag.transform.setLocalPositionY(mapfactor.y);

        console.log(location, this.hereTag.transform.getLocalPosition());
    }

    update() {

    }
}
