import * as Mobilizing from '@mobilizing/library';

export class Script {

    constructor() {
        //to take care of user interaction needed for
        //audio context creation or sensors
        this.needsUserInteraction = true;
    }

    preLoad(loader) {
    }

    setup() {

        this.renderer = new Mobilizing.three.RendererThree();
        this.context.addComponent(this.renderer);

        this.camera = new Mobilizing.three.Camera();
        this.renderer.addCamera(this.camera);
        this.camera.transform.setLocalPositionZ(200);

        const light = new Mobilizing.three.Light();
        light.transform.setLocalPosition(10, 10, 10);
        this.renderer.addToCurrentScene(light);

        const mouse = new Mobilizing.input.Mouse({ target: this.renderer.getCanvas() });
        this.context.addComponent(mouse);
        mouse.setup();
        mouse.on();

        this.audioStarted = false;

        if (!this.audioStarted) {
            this.audioStarted = true;
        }
        else {
            return;
        }

        //we MUST define a callback to grab the mediaStream from the mic
        this.mic = new Mobilizing.input.UserMedia({
            deviceKind: "audioinput",
            callback: this.micCallback.bind(this)
        });

        this.context.addComponent(this.mic);
        this.mic.setup();
        this.mic.on();

        //console.log(this.mic);

        //build the audio renderer
        this.audioRenderer = new Mobilizing.audio.Renderer();
        this.context.addComponent(this.audioRenderer);

        //make an analyser to get sound frequencies
        //this analyser is automatically connected at the end of the node graph,
        //that is after the mainGain Node in the audioRenderer
        this.audioRenderer.createAnalyzer(1024, .3);

        //make an audio source from the mic stream
        this.micAudioSource = new Mobilizing.audio.Source({ "renderer": this.audioRenderer });

        //prepare vizualisation, everything is already in the audio renderer
        const freqNb = this.audioRenderer.analyserFrequencyArray.length;
        console.log("freqNb", freqNb);

        //make as many boxes as the nb of frequencies we analyse
        this.boxes = [];
        for (let i = 0; i < freqNb; i++) {
            const box = new Mobilizing.three.Box({ "width": .1 });
            this.renderer.addToCurrentScene(box);
            box.transform.setLocalPositionX(i - freqNb / 2);
            this.boxes.push(box);
        }
    }

    //when the usermedia is ready to be used, it gives a MediaStream to work with
    micCallback(media) {
        console.log("media", media);
        //AudioSource class make it possible the generate a new source from the audioStream
        //here, we decide not to rendrer the sound : 2nd arg will disconnect this source from the masterGain of the audio renderer
        this.micAudioSource.createFromMediaStream(media.audioStream, false);
        //we must connect manually this audio source the the analyser in the renderer!!
        this.micAudioSource.connect(this.audioRenderer.analyser);
    }

    update() {

        if (this.audioStarted) {
            if (this.audioRenderer.analyser && this.audioRenderer.analyserFrequencyArray) {
                this.audioRenderer.analyser.getByteFrequencyData(this.audioRenderer.analyserFrequencyArray);

                const freqNb = this.audioRenderer.analyserFrequencyArray.length;
                for (let i = 0; i < freqNb; i++) {
                    this.boxes[i].transform.setLocalScaleY(this.audioRenderer.analyserFrequencyArray[i] / 5);
                }
            }
        }
    }
}
